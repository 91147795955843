<template>
  <b-container>
    <create-integration-modal ref="createIntegrationModal" />
    <page-head-bar>
      <template #inner>
        <div class="d-flex justify-content-end">
          <a
            @click.prevent="handleCreateNewIntegrationClick"
            class="btn btn-dark"
            >{{
              T(
                "Web.IntegrationsPage.CreateNewIntegration",
                "Create new integration"
              )
            }}</a
          >
        </div>
      </template>
    </page-head-bar>
    <b-card no-body>
      <transition name="fade" mode="out-in">
        <div class="d-flex justify-content-center mt-2 mb-2" v-if="loading">
          <b-spinner />
        </div>
        <b-table
          v-else
          responsive
          show-empty
          :empty-text="T('Web.Generic.TableEmpty')"
          :fields="tableFields"
          :items="integrations"
          class="mt-3"
        >
          <template #cell(type)="data">
            {{ getIntegrationTypeFriendlyName(data.value) }}
          </template>
          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="flat-danger"
                @click="deleteIntegration(data.item)"
                >{{ T("Web.Generic.Remove", "Remove") }}
                <feather-icon icon="TrashIcon" size="16" />
              </b-button>
            </div>
          </template>
        </b-table>
      </transition>
    </b-card>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BContainer, BCard, BTable, BSpinner, BButton } from "bootstrap-vue";
import PageHeadBar from "@/components/layout/PageHeadBar.vue";
import createIntegrationModal from "./CreateIntegrationModal.vue";
export default {
  components: {
    BContainer,
    BCard,
    BTable,
    BSpinner,
    BButton,
    PageHeadBar,
    createIntegrationModal,
  },
  created() {
    this.fetchIntegrations();
  },
  data() {
    return {
      tableFields: [
        {
          key: "name",
          label: this.T("Web.Generic.Name", "Name"),
          sortable: true,
        },
        {
          key: "type",
          label: this.T("Web.Generic.Type", "Type"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loading: "integrations/isLoading",
      integrations: "integrations/list",
    }),
  },
  methods: {
    ...mapActions({
      fetchIntegrations: "integrations/fetchList",
      deleteFtpImportIntegration: "integrations/deleteFtpImportIntegration",
      deleteAzureBlobIntegration: "integrations/deleteAzureBlobIntegration",
    }),
    handleCreateNewIntegrationClick() {
      this.$refs.createIntegrationModal.open();
    },
    deleteIntegration(integration) {
      if (integration.type == "FTP_IMPORT") {
        this.deleteFtpImportIntegration(integration.id);
      } else if (integration.type == "AZURE_BLOB") {
        this.deleteAzureBlobIntegration(integration.id);
      }
    },
    getIntegrationTypeFriendlyName(integrationType) {
      switch (integrationType) {
        case "FTP_IMPORT":
          return this.T("Web.IntegrationsPage.Types.FTP", "FTP import");
        case "AZURE_BLOB":
          return this.T(
            "Web.IntegrationsPage.Types.AzureBlob",
            "Azure Blob Storage"
          );
        default:
          this.T("Web.Generic.Unknown", "Unknown");
      }
    },
  },
};
</script>