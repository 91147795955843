<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group :label="T('Web.IntegrationsPage.ImportFileType', 'Import file type')">
          <b-form-select
            v-model="data.importFileType"
            :options="options.importFileTypeOptions"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group :label="T('Web.IntegrationsPage.ImportFileEncoding', 'Import file encoding')"
          ><b-form-select
            v-model="data.importFileEncoding"
            :options="options.importFileEcondingOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-form-group :label="T('Web.IntegrationsPage.FTPHost', 'FTP Host')">
      <b-form-input v-model="data.ftpHost" />
    </b-form-group>
    <b-form-group :label="T('Web.IntegrationsPage.FTPFolder', 'FTP Folder')">
      <b-form-input v-model="data.ftpFolder" />
    </b-form-group>
    <b-form-group :label="T('Web.IntegrationsPage.FTPUsername', 'FTP Username')">
      <b-form-input v-model="data.ftpUsername" />
    </b-form-group>
    <b-form-group :label="T('Web.IntegrationsPage.FTPPassword', 'FTP Password')">
      <b-form-input v-model="data.ftpPassword" />
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { BRow, BCol, BFormGroup, BFormSelect, BFormInput } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
  },
  data() {
    return {
      options: {
        importFileTypeOptions: [
          { text: this.T("Web.IntegrationsPage.SelectImportFileType", "Select file type"), value: null },
          { text: "XML/OIOUBL", value: "XML" },
        ],
        importFileEcondingOptions: [
          { text: this.T("Web.IntegrationsPage.SelectImportFileEncoding", 'Select encoding'), value: null },
          { text: "UFT-8", value: "UTF8" },
          { text: "Windows 1252", value: "Windows1252" },
        ],
      },
      data: this.newFtpImportIntegrationData(),
    };
  },
  computed: {
    canSubmit() {
      return (
        this.data.importFileType != null &&
        this.data.importFileEncoding != null &&
        this.data.ftpHost != "" &&
        this.data.ftpUsername != ""
      );
    },
  },
  methods: {
    ...mapActions({
      createIntegration: "integrations/createFtpImportIntegration",
    }),
    async submit() {
      try {
        await this.createIntegration({
          data: this.data,
        });
      } catch (error) {
        throw error;
      }
    },
    newFtpImportIntegrationData() {
      return {
        importFileType: null,
        importFileEncoding: null,
        ftpHost: "",
        ftpFolder: "/",
        ftpUsername: "",
        ftpPassword: "",
      };
    },
  },
  watch: {
    canSubmit(newValue, oldValue) {
      if (newValue == oldValue) return;
      this.$emit("can-submit-change", newValue);
    },
  },
};
</script>