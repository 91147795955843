<template>
  <div>
    <b-modal
      id="booststrapmodal"
      :title="
        T('Web.IntegrationsPage.CreateIntegration', 'Create a new integration')
      "
      centered
      :visible="visible"
      @hidden="handleModalHidden"
    >
      {{ integrationData }}
      <b-form-group :label="T('Web.Generic.Type', 'Type')">
        <b-form-select
          v-model="integrationType"
          :options="integrationTypeOptions"
        />
      </b-form-group>

      <ftp-integration-form
        ref="form"
        v-if="integrationType == 'FTP_IMPORT'"
        @can-submit-change="(value) => (canSubmit = value)"
      />
      <azure-blob-integration-form
        ref="form"
        v-if="integrationType == 'AZURE_BLOB'"
        @can-submit-change="(value) => (canSubmit = value)"
      />

      <!-- Footer -->
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :disabled="!canSubmit || submitting"
            @click="handleSubmit"
          >
            {{ T("Web.Generic.Create", "Create") }}
          </b-button>
          <b-button
            variant="light"
            class="float-right mr-2"
            @click="handleCancel"
          >
            {{ T("Web.Generic.Cancel", "Cancel") }}
          </b-button>
        </div>
      </template>
      <!-- End footer -->
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BFormGroup,
  BFormSelect,
  BRow,
  BCol,
  BFormInput,
} from "bootstrap-vue";
import FtpIntegrationForm from "./FtpIntegrationForm.vue";
import AzureBlobIntegrationForm from "./AzureBlobIntegrationForm.vue";
export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BFormInput,
    FtpIntegrationForm,
    AzureBlobIntegrationForm,
  },
  data() {
    return {
      visible: false,
      submitting: false,
      canSubmit: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
      integrationType: null,
      integrationTypeOptions: [
        {
          text: this.T(
            "Web.IntegrationsPage.SelectIntegrtionType",
            "Select integration type"
          ),
          value: null,
        },
        {
          text: this.T("Web.IntegrationsPage.Types.FTP", "FTP import"),
          value: "FTP_IMPORT",
        },
        {
          text: this.T(
            "Web.IntegrationsPage.Types.AzureBlob",
            "Azure Blob Storage"
          ),
          value: "AZURE_BLOB",
        },
      ],
      integrationData: undefined,
    };
  },
  methods: {
    open() {
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async handleSubmit() {
      try {
        this.submitting = true;
        await this.$refs.form.submit();
        this.handleModalHidden();
      } finally {
        this.submitting = false;
      }
    },
    handleModalHidden() {
      this.visible = false;
      this.integrationType = null;
    },
    handleCancel() {
      this.handleModalHidden();
      this.resolvePromise(false);
      this.handleModalHidden();
    },
  },
};
</script>
